<template>
  <v-fade-transition>
    <v-overlay
      v-if="loading"
      absolute
    >
      <v-progress-circular indeterminate>
      </v-progress-circular>
    </v-overlay>
  </v-fade-transition>
</template>
<script>
  export default {
    props: ['loading'],
    data: () => ({
    }),
    computed: {
    },
    methods: {
    }
  }
</script>