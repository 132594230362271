<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col
        class="hidden-sm-and-down"
        cols="12"
        md="6"
      >
        <v-img
          class="mx-8 my-4"
          :src="require('@/assets/graffiti_4.jpeg')"
          contain
          height="90vh"
        />
      </v-col>
      <v-col
        class="align-content-space-between layout wrap"
        cols="12"
        md="6"
      >
        <v-row
          class="mt-8"
          align="center"
          justify="center"
        >
          <v-col
            cols="10"
            md="6"
          > 
            
            <div class="display-3 v-heading mb-3 font-weight-light">Welcome</div>
            <p class="title font-weight-light" style="line-height: 1.7 !important;">
              Santa Clarita's Graffiti Tracking System is a centralized, web-based application that allows multiple agencies to manage graffiti removal requests, open cases and reporting all through the Internet. The Graffiti Tracking System allows any user that has access to the Internet with the ability to open a case that captures pertinent information.

              
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>  
  </v-container>
</template>

<script>
  export default {
    name: 'HelloWorld',
  }
</script>
