<template>
  <div>
    <v-alert type="success" dismissible :value="successmsg != undefined && successmsg.length > 0" @input="close">
      {{ successmsg }}
    </v-alert>
    <v-alert type="error" dismissible :value="errormsg != undefined && errormsg.length > 0" @input="close">
      {{ errormsg }}
    </v-alert>
  </div>
</template>
<script>
  export default {
    props: ['successmsg', 'errormsg'],
    data: () => ({
    }),
    computed: {
    },
    methods: {
      close() {
        this.$emit('close')
      }
    }
  }
</script>