import router from "@/router"
import Vue from 'vue'
import { getError } from "@/utils/helpers"

export default {
  namespaced: true,
  state: {
    authenticated: false,
    user: null,
    errors: [],
    error_msg: '',
    success_msg: '',
    loading: false
  },

  getters: {
    user (state) {
      return state.user
    },
    authUser (state) {
      return state.user
    },
    authenticated (state) {
      return state.authenticated
    },
    isAdmin: (state) => {
      return state.user ? state.user.is_admin : false;
    },
    isReadOnly: (state) => {
      return state.user ? state.user.is_read_only : false;
    },
    errors (state) {
      return state.errors
    },
    error_msg (state) {
      return state.error_msg
    },
    success_msg (state) {
      return state.success_msg
    },
    loading (state) {
      return state.loading
    }
  },

  mutations: {
    RESET_MSGS: function(state) {
      state.errors = []
      state.error_msg = ""
      state.success_msg = ""
    },
    SET_ERRORS: function(state, value) {
      state.errors = getError(value)
      state.error_msg = value.response.data.message
    },
    SET_USER: function(state, value) { state.user = value },
    SET_ERROR_MSG: function(state, value) { state.error_msg = value },
    SET_SUCCESS_MSG: function(state, value) { state.success_msg = value },
    SET_AUTHENTICATED: function(state, value) { state.authenticated = value },
    SET_LOADING: function(state, value) { state.loading = value }
  },
  actions: {
    async login ({ commit, dispatch }, credentials) {
      commit('RESET_MSGS')
      commit('SET_LOADING', true)
      await Vue.axios.get('/sanctum/csrf-cookie')
        .catch(() => {
          commit('SET_LOADING', false)
        })
      await Vue.axios.post('/login', credentials)
        .then(() => {})
        .catch((error) => {
          commit('SET_LOADING', false)
          commit('SET_ERRORS', error)
      })
      return dispatch('getAuthUser')
    },

    logout ({ commit }) {
      commit('RESET_MSGS')
      commit('SET_USER', null)
      commit('SET_AUTHENTICATED', false)
      Vue.axios.post('/logout')
      if (router.currentRoute.name !== "Login")
          router.push({ path: "/login" });
    },

    async forgotPassword({ commit }, payload) {
      commit('RESET_MSGS')
      commit('SET_LOADING', true)
      await Vue.axios.get("/sanctum/csrf-cookie")
      await Vue.axios.post('/forgot-password', payload).then((response) => {
        commit('SET_SUCCESS_MSG', response.data.message)
        commit('SET_LOADING', false)
      }).catch((error) => {
        commit('SET_LOADING', false)
        commit('SET_ERRORS', error)
      })
    },

    async resetPassword({ commit }, payload) {
      commit('RESET_MSGS')
      commit('SET_LOADING', true)
      await Vue.axios.get("/sanctum/csrf-cookie")
      Vue.axios.post('/reset-password', payload).then((response) => {
        commit('SET_SUCCESS_MSG', response.data.message)
        router.push({ path: "/login" });
        commit('SET_LOADING', false)
      }).catch((error) => {
        commit('SET_LOADING', false)
        commit('SET_ERRORS', error)
      })
    },

    async changePassword({ commit }, payload) {
      commit('RESET_MSGS')
      commit('SET_LOADING', true)
      await Vue.axios.get("/sanctum/csrf-cookie")
      return Vue.axios.put('/user/password', payload).then((response) => {
        commit('SET_SUCCESS_MSG', response.data.message)
        commit('SET_LOADING', false)
        return true
      }).catch((error) => {
        commit('SET_LOADING', false)
        commit('SET_ERRORS', error)
        return false
      })
    },

    setAuthenticated( { commit }, payload) {
      commit('SET_AUTHENTICATED', payload)
    },
    getAuthUser ({ commit }) {
      return Vue.axios.get('/api/user').then((response) => {
        commit('SET_AUTHENTICATED', true)
        commit('SET_USER', response.data.user)
        commit('SET_LOADING', false)
      }).catch(() => {
        //commit('SET_USER', null)
        commit('SET_LOADING', false)
      })
    }
  }
}