//import router from "@/router"
import Vue from 'vue'
import { getError } from "@/utils/helpers"

export default {
  namespaced: true,
  state: {
    errors: [],
    error_msg: '',
    success_msg: '',
    loading: false,
    formValues: [],
    prevNewCase: [],

    //paginate
    graffiti: []
  },
  getters: {
    errors (state) {
      return state.errors
    },
    error_msg (state) {
      return state.error_msg
    },
    success_msg (state) {
      return state.success_msg
    },
    loading (state) {
      return state.loading
    },
    getFormValues(state) {
      return state.formValues
    },
    getPrevNewCase(state) {
      return state.prevNewCase
    },
    getEditCase(state) {
      return state.edit_case
    },
    searchCases: (state) => {
      return state.searchCases;
    },
    outstandingCases: (state) => {
      return state.outstandingCases;
    },
    cases: (state) => {
      return state.cases;
    },
  },
  mutations: {
    RESET_MSGS: function(state) { 
      state.errors = [] 
      state.error_msg = ""
      state.success_msg = ""
    },
    SET_ERRORS: function(state, value) { state.errors = value },
    SET_ERROR_MSG: function(state, value) { state.error_msg = value },
    SET_SUCCESS_MSG: function(state, value) { state.success_msg = value },
    SET_LOADING: function(state, value) { state.loading = value },
    SET_FORM_VALUES: function(state, value) { state.formValues = value },
    SET_PREV_NEW_CASE: function(state, value) { state.prevNewCase = value },
    SET_CURRENT_NEW_CASE: function(state, value) { state.prevNewCase[value[1]] = value[0] }, //Update current case
    SET_NEW_CASE: function(state, value) { state.prevNewCase.push(value) }, // Add a new case
    DELETE_CASE: function(state, value) { state.prevNewCase.splice(value, 1) },

    SET_SEARCH_CASES(state, cases) {
      state.searchCases = cases
    },
    SET_OUTSTANDING_CASES(state, cases) {
      state.outstandingCases = cases
    },
    SET_CASES(state, cases) {
      state.cases = cases
    },
    SET_META(state, meta) {
      state.meta = meta
    },
    SET_LINKS(state, links) {
      state.links = links
    }
  },
  actions: {
    getFormValues ({ commit }) {
      commit('RESET_MSGS')
      commit('SET_LOADING', true)
      Vue.axios.get('api/cases/getFormValues')  
        .then((response) => {
          commit('SET_LOADING', false)
          commit('SET_FORM_VALUES', response.data)
        })   
        .catch((error) => {
          commit('SET_LOADING', false)
          commit('SET_ERRORS', getError(error))
          commit('SET_ERROR_MSG', error.response.data.message)
        })
    },
    getGraffiti({ commit }, payload) {
      commit('RESET_MSGS')
      commit('SET_LOADING', true)
      return Vue.axios.get('api/graffiti/getGraffiti/' + payload)
        .then((response) => {
          commit('SET_LOADING', false)
          return response.data.graffiti
        })   
        .catch((error) => {
          commit('SET_LOADING', false)
          commit('SET_ERRORS', getError(error))
          commit('SET_ERROR_MSG', error.response.data.message)
        })
    },
    async getImages( {commit }, payload) {
      commit('RESET_MSGS')
      commit('SET_LOADING', true)
      return await Vue.axios.get('api/graffiti/getImages/' + payload)
        .then((response) => {
          commit('SET_LOADING', false)
          return response.data
          //return response.data.graffiti
        })   
        .catch((error) => {
          commit('SET_LOADING', false)
          commit('SET_ERRORS', getError(error))
          commit('SET_ERROR_MSG', error.response.data.message)
          return false
        })
    },
    getEditGraffiti({ commit }, payload) {
      //commit('RESET_MSGS')
      commit('SET_LOADING', true)
      return Vue.axios.get('api/graffiti/getGraffiti/' + payload)
        .then((response) => {
          commit('SET_LOADING', false)
          return response.data.graffiti
        })   
        .catch((error) => {
          commit('SET_LOADING', false)
          commit('SET_ERRORS', getError(error))
          commit('SET_ERROR_MSG', error.response.data.message)
        })
    },
    async updateGraffiti({ commit }, payload) {
      commit('RESET_MSGS')
      commit('SET_LOADING', true)
      const formData = jsonToFormData(payload)
      return await Vue.axios.post('api/graffiti/updateGraffiti', formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          },
        })
        .then((response) => {
          commit('SET_LOADING', false)
          commit('SET_SUCCESS_MSG', response.data.message)
          return true
        })   
        .catch((error) => {
          commit('SET_LOADING', false)
          commit('SET_ERRORS', getError(error))
          commit('SET_ERROR_MSG', error.response.data.message)
          return false
        })
        function buildFormData(formData, data, parentKey) {
          if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
            Object.keys(data).forEach(key => {
              buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
            });
          } else {
            const value = data == null ? '' : data;
        
            formData.append(parentKey, value);
          }
        }
        function jsonToFormData(data) {
          const formData = new FormData();
          buildFormData(formData, data);
          return formData;
        }
    }
  }
}