//Used By Form Management Page
import Vue from 'vue'
import { getError } from "@/utils/helpers"

export default {
  namespaced: true,
  state: {
    errors: [],
    error_msg: '',
    success_msg: '',
    loading: false,
    token: null,
  },

  getters: {
    errors (state) {
      return state.errors
    },
    error_msg (state) {
      return state.error_msg
    },
    success_msg (state) {
      return state.success_msg
    },
    loading (state) {
      return state.loading
    },
    getToken (state) {
      return state.token
    }
  },

  mutations: {
    RESET_MSGS: function(state) { 
      state.errors = [] 
      state.error_msg = ""
      state.success_msg = ""
    },
    SET_ERRORS: function(state, value) { 
      state.errors = getError(value)
      state.error_msg = value.response.data.message
    },
    SET_ERROR_MSG: function(state, value) { state.error_msg = value },
    SET_SUCCESS_MSG: function(state, value) { state.success_msg = value },
    SET_LOADING: function(state, value) { state.loading = value },
    SET_TOKEN: function(state, value) { state.token = value }
  },
  actions: {
    async retrieveToken ({ commit }) {
      commit('RESET_MSGS')
      commit('SET_LOADING', true)
      await Vue.axios.get('api/arcgis/getToken')
        .then((response) => {
          commit('SET_TOKEN', response.data.token)
          commit('SET_LOADING', false)
          return true
        })
        .catch((error) => {
          commit('SET_ERRORS', error)
          commit('SET_LOADING', false)
      })
    },
  }
}