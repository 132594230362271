import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store/index"
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'

const NotFound = () => import('../views/404')
const About = () => import('../views/About')
const UserManagement = () => import('../views/UserManagement')
const AddCase = () => import('../views/AddCase')
const Tools = () => import('../views/Tools')
const GroupBy = () => import('../views/Reports/GroupBy')
const Restitution = () => import('../views/Reports/Restitution')
const RemovalOnTimeReport = () => import('../views/Reports/RemovalOnTime')
const MonthlyReport = () => import('../views/Reports/MonthlyReport')
const WeeklyReport = () => import('../views/Reports/WeeklyReport')
const StatTrackerReport = () => import('../views/Reports/StatTrackerReport')
const YearToDateReport = () => import('../views/Reports/YearToDateReport')
const EditCase = () => import('../views/EditCase')
const EditGraffiti = () => import('../views/EditGraffiti')
const ViewParentCase = () => import('../views/ViewParentCase')
const ViewGraffiti = () => import('../views/ViewGraffiti')
const SearchCases = () => import('../views/SearchCases')
const SearchPictures = () => import('../views/SearchPictures')
const PendingCases = () => import('../views/PendingCases')
const FormManagement = () => import('../views/FormManagement')
const Management = () => import('../views/Management/Index')
const AgencyManagement = () => import('../views/FormManagement/Agencies')
const InfoRouteManagement = () => import('../views/FormManagement/InfoRoutes')
const PayGradeManagement = () => import('../views/FormManagement/PayGrades')
const RemovalMethodManagement = () => import('../views/FormManagement/RemovalMethods')
const Inventory = () => import('../views/Inventory')
const SupplyManagement = () => import('../views/FormManagement/Supplies')
const SurfaceManagement = () => import('../views/FormManagement/Surfaces')
const TaggerManagement = () => import('../views/FormManagement/Taggers')
const VandalToolManagement = () => import('../views/FormManagement/VandalTools')
const IncidentMap = () => import('../views/IncidentMap')

import ForgotPassword from '../views/ForgotPassword.vue'
import ResetPassword from '../views/ResetPassword.vue'
import EditPassword from '../views/EditPassword.vue'

import auth from '@/middleware/auth'
import guest from '@/middleware/guest'
import admin from '@/middleware/admin'
import middlewarePipeline from '@/router/middlewarePipeline'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { middleware: [guest] },
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    meta: { middleware: [guest] },
    component: Login
  },
  {
    path: '/forgotPassword',
    name: 'ForgotPassword',
    meta: { middleware: [guest] },
    component: ForgotPassword
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    meta: { middleware: [guest] },
    component: ResetPassword
  },
  {
    path: '/editPassword',
    name: 'EditPassword',
    meta: { middleware: [auth] },
    component: EditPassword
  },
  {
    path: '/about',
    name: 'About',
    meta: { middleware: [auth] },
    component: About
  },
  {
    path: '/addCase',
    name: 'AddCase',
    meta: { middleware: [auth] },
    component: AddCase
  },
  {
    path: '/tools',
    name: 'Tools',
    meta: { middleware: [auth] },
    component: Tools,
  },
  {
    path: '/reports/removalOnTimeReport',
    name: 'RemovalOnTimeReport',
    meta: { middleware: [auth] },
    component: RemovalOnTimeReport,
  },
  {
    path: '/reports/monthlyReport',
    name: 'MonthlyReport',
    meta: { middleware: [auth] },
    component: MonthlyReport,
  },
  {
    path: '/reports/weeklyReport',
    name: 'WeeklyReport',
    meta: { middleware: [auth] },
    component: WeeklyReport,
  },
  {
    path: '/reports/yearToDateReport',
    name: 'YearToDateReport',
    meta: { middleware: [auth] },
    component: YearToDateReport,
  },
  {
    path: '/reports/statTrackerReport',
    name: 'StatTrackerReport',
    meta: { middleware: [auth] },
    component: StatTrackerReport,
  },
  {
    path: '/reports/groupBy',
    name: 'ReportGroupBy',
    meta: { middleware: [auth] },
    component: GroupBy,
  },
  {
    path: '/reports/restitution',
    name: 'ReportRestitution',
    meta: { middleware: [auth] },
    component: Restitution,
  },
  {
    path: '/map',
    name: 'IncidentMap',
    meta: { middleware: [auth] },
    component: IncidentMap,
  },
  {
    path: '/editCase/:id',
    name: 'EditCase',
    meta: { middleware: [auth] },
    component: EditCase
  },
  {
    path: '/editGraffiti/:id',
    name: 'EditGraffiti',
    meta: { middleware: [auth] },
    component: EditGraffiti
  },
  {
    path: '/viewCase/:id',
    name: 'ViewCase',
    meta: { middleware: [auth] },
    component: ViewParentCase
  },
  {
    path: '/viewGraffiti/:id',
    name: 'ViewGraffiti',
    meta: { middleware: [auth] },
    component: ViewGraffiti
  },
  {
    path: '/pendingCases',
    name: 'PendingCases',
    meta: { middleware: [auth] },
    component: PendingCases
  },
  {
    path: '/searchCases',
    name: 'SearchCases',
    meta: { middleware: [auth] },
    component: SearchCases
  },
  {
    path: '/searchPictures',
    name: 'SearchPictures',
    meta: { middleware: [auth] },
    component: SearchPictures
  },
  {
    path: '/userManagement',
    name: 'UserManagement',
    meta: { middleware: [auth, admin] },
    component: UserManagement
  },
  //Form
  {
    path: '/formManagement',
    name: 'FormManagement',
    meta: { middleware: [auth, admin] },
    component: FormManagement
  },
  {
    path: '/management',
    name: 'Management',
    meta: { middleware: [auth, admin] },
    component: Management
  },
  {
    path: '/formManagement/agencies',
    name: 'Agency',
    meta: { middleware: [auth, admin] },
    component: AgencyManagement
  },
  {
    path: '/formManagement/inforoutes',
    name: 'InfoRoute',
    meta: { middleware: [auth, admin] },
    component: InfoRouteManagement
  },
  {
    path: '/formManagement/paygrades',
    name: 'PayGrade',
    meta: { middleware: [auth, admin] },
    component: PayGradeManagement
  },
  {
    path: '/formManagement/removalmethods',
    name: 'RemovalMethod',
    meta: { middleware: [auth, admin] },
    component: RemovalMethodManagement
  },
  {
    path: '/formManagement/supplies',
    name: 'Supply',
    meta: { middleware: [auth, admin] },
    component: SupplyManagement
  },
  {
    path: '/formManagement/surfaces',
    name: 'Surface',
    meta: { middleware: [auth, admin] },
    component: SurfaceManagement
  },
  {
    path: '/formManagement/taggers',
    name: 'Tagger',
    meta: { middleware: [auth, admin] },
    component: TaggerManagement
  },
  {
    path: '/formManagement/vandaltools',
    name: 'VandalTool',
    meta: { middleware: [auth, admin] },
    component: VandalToolManagement
  },
  {
    path: '/inventory',
    name: 'Inventory',
    meta: { middleware: [auth, admin] },
    component: Inventory
  },
  {
    path: "/404",
    alias: '*',
    name: "NotFound",
    component: NotFound
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
})

const waitForStorageToBeReady = async (to, from, next) => {
  await store.restored
  next()
}
router.beforeEach(waitForStorageToBeReady)
router.beforeEach( (to, from, next) => {
  const middleware = to.meta.middleware;
  const context = { to, from, next, store };
  if (!middleware) {
    return next()
  }

  
  middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  })
})

export default router
