import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from "vuex-persist"
//import createMultiTabState from 'vuex-multi-tab-state'
import LocalForage from "localforage";
import createLogger from "vuex/dist/logger"

import arcgis from './modules/arcgis'
import auth from './modules/auth'
import cases from './modules/cases'
import users from './modules/users'
import chats from './modules/chats'
import agencies from './modules/agencies'
import graffiti from './modules/graffiti'
import inforoutes from './modules/inforoutes'
import paygrades from './modules/paygrades'
import reports from './modules/reports'
import removalmethods from './modules/removalmethods'
import supplies from './modules/supplies'
import surfaces from './modules/surfaces'
import taggers from './modules/taggers'
import vandaltools from './modules/vandaltools'
import beautification_opts from './modules/beautification_opts'
import excel from './modules/excel'

var pjson = require('../../package.json')

const debug = process.env.NODE_ENV !== "production"

LocalForage.config({
  version     : pjson.version,
  storeName   : 'keyvaluepairs', // Should be alphanumeric, with underscores.
  description : 'some description'
});

const vuexPersist = new VuexPersistence({
  strictMode: debug,
  version : pjson.version,
  storage: LocalForage,
  asyncStorage: true,
  async restoreState(key) {
    const state = await LocalForage.getItem(key);
    // Check if state is a valid object before manipulating its prototype
    if (typeof state === 'object' && state !== null) {
      // Use Object.setPrototypeOf to set the prototype
      Object.setPrototypeOf(state, Object.prototype);
      return state;
    } else {
      // Handle the case where state is not a valid object
      console.error('Invalid state:', state);
      return null; // Or handle it based on your specific use case
    }
  },
  // Specify here which modules should be persistent:
  modules: ["auth", "cases", "users", "chats", "reports", "graffiti"]
})

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
    RESTORE_MUTATION: function( state, savedState ) {
      const mergedState = Object.assign(state, savedState)
      for (const propertyName of Object.keys(mergedState)) {
        this._vm.$set(state, propertyName, (mergedState )[propertyName])
      }
    }
  },
  actions: {
  },
  modules: {
    arcgis, auth, cases, users, chats, 
    supplies, agencies, inforoutes, 
    paygrades, reports, removalmethods, surfaces, vandaltools,
    taggers, graffiti, beautification_opts, excel
  },
  //plugins: debug ? [createMultiTabState(), vuexPersist.plugin, createLogger()]  : [createMultiTabState(), vuexPersist.plugin],
  plugins: debug ? [ vuexPersist.plugin, createLogger()]  : [vuexPersist.plugin]
})
