"use strict";

import Vue from 'vue';
import axios from "axios";
import store from "@/store"

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken  = true;


let config = {
  baseURL: process.env.VUE_APP_BACKEND_API,
  // timeout: 60 * 1000, // Timeout
  withCredentials: true, // Check cross-site Access-Control
  withXSRFToken : true,
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    if (
      error.response &&
      (store.getters["auth/authUser"] != null || typeof store.getters["auth/authUser"] === 'object') &&
      store.getters["auth/authenticated"] &&
      [401, 419].includes(error.response.status)
    ) {
      console.log('Logout')
      store.dispatch("auth/logout")
    }
    return Promise.reject(error)
  }
);

Plugin.install = function(Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;
