import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import './plugins/axios'
import './plugins/echo'
import GmapVue from 'gmap-vue'
import VueTheMask from 'vue-the-mask'

Vue.use(GmapVue, {
  load: {
    key: 'AIzaSyCIUHiNwvZEYyxwwSAtnzVNMzZWrqde7u8'
  },
  installComponents: true
})
Vue.use(VueTheMask)

Vue.config.productionTip = false
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
