//import router from "@/router"
import Vue from 'vue'
import { getError } from "@/utils/helpers"
import CaseService from "@/services/CaseService";

function setPaginatedCases(commit, response, func) {
  commit(func, response.data.data);
  commit("SET_META", response.data.meta);
  commit("SET_LINKS", response.data.links);
  commit("SET_LOADING", false);
}

function setPaginatedPictures(commit, response, func) {
  commit(func, response.data.data);
  commit("SET_META", response.data.meta);
  commit("SET_LINKS", response.data.links);
  commit("SET_LOADING", false);
}

export default {
  namespaced: true,

  state: {
    errors: [],
    error_msg: '',
    success_msg: '',
    loading: false,
    formValues: [],
    prevNewCase: [],

    //paginate
    outstandingCases: [],
  
    //Find Cases
    searchCases: [],
    meta: null,
    links: null,
    caseSearchOptions: {
      status_id: [],
      case_no: null,
      start_date_reported_at: null,
      end_date_reported_at: null,
      primary_staff: null,
      secondary_staff: null,
      tagger_id: null,
      surface_id: null,
      vandal_tool_id: null,
      removal_method_id: null,
      types: []
    },
    defaultTableOptions: {
      groupBy: [],
      groupDesc: [],
      itemsPerPage: 25,
      multiSort: false,
      mustSort: false,
      page: 1,
      sortBy: [],
      sortDesc: []
    },

    //Search Pictures
    searchPictures: [],
    pictureMeta: null,
    pictureLinks: null,
    pictureOptions: {
      status_id: [],
      case_no: null,
      start_date_reported_at: null,
      end_date_reported_at: null,
      primary_staff: null,
      secondary_staff: null,
      tagger_id: null,
      surface_id: null,
      vandal_tool_id: null,
      removal_method_id: null,
      types: []
    },
    defaultPictureTableOptions: {
      groupBy: [],
      groupDesc: [],
      itemsPerPage: 10,
      multiSort: false,
      mustSort: false,
      page: 1,
      sortBy: [],
      sortDesc: []
    }
  },
  getters: {
    errors (state) {
      return state.errors
    },
    error_msg (state) {
      return state.error_msg
    },
    success_msg (state) {
      return state.success_msg
    },
    loading (state) {
      return state.loading
    },
    getFormValues(state) {
      return state.formValues
    },
    getPrevNewCase(state) {
      return state.prevNewCase
    },
    getEditCase(state) {
      return state.edit_case
    },
    outstandingCases: (state) => {
      return state.outstandingCases
    },

    //Find Cases
    searchCases: (state) => {
      return state.searchCases;
    },
    meta: (state) => {
      return state.meta
    },
    links: (state) => {
      return state.links
    },
    caseSearchOptions: (state) => {
      return state.caseSearchOptions
    },
    defaultTableOptions: (state) => {
      return state.defaultTableOptions
    },

    //Search Pictures
    searchPictures: (state) => {
      return state.searchPictures;
    },
    pictureMeta: (state) => {
      return state.pictureMeta
    },
    pictureLinks: (state) => {
      return state.pictureLinks
    },
    pictureOptions: (state) => {
      return state.pictureOptions
    },
    defaultPictureTableOptions: (state) => {
      return state.defaultPictureTableOptions
    }
    
  },

  mutations: {
    RESET_MSGS: function(state) { 
      state.errors = [] 
      state.error_msg = ""
      state.success_msg = ""
    },
    SET_ERRORS: function(state, value) { state.errors = value },
    SET_ERROR_MSG: function(state, value) { state.error_msg = value },
    SET_SUCCESS_MSG: function(state, value) { state.success_msg = value },
    SET_LOADING: function(state, value) { state.loading = value },
    SET_FORM_VALUES: function(state, value) { state.formValues = value },
    SET_PREV_NEW_CASE: function(state, value) { state.prevNewCase = value },
    SET_CURRENT_NEW_CASE: function(state, value) { 
      state.prevNewCase[value[1]] = value[0]
    }, //Update current case
    SET_NEW_CASE: function(state, value) { state.prevNewCase.push(value) }, // Add a new case
    DELETE_CASE: function(state, value) { state.prevNewCase.splice(value, 1) },
    
    //Find Cases Page
    SET_DEFAULT_CASE_TABLE_OPTIONS: function(state, value) { state.defaultTableOptions = value },
    SET_CASE_SEARCH_OPTIONS: function(state, value) { state.caseSearchOptions = value },
    SET_SEARCH_CASES(state, cases) {
      state.searchCases = cases
    },
    SET_META(state, meta) {
      state.meta = meta
    },
    SET_LINKS(state, links) {
      state.links = links
    },

    //Search Pictures Page
    SET_DEFAULT_PICTURE_TABLE_OPTIONS: function(state, value) { state.defaultPictureTableOptions = value },
    SET_PICTURE_SEARCH_OPTIONS: function(state, value) { state.pictureOptions = value },
    SET_SEARCH_PICTURES(state, cases) {
      state.searchPictures = cases
    },
    SET_PICTURE_META(state, meta) {
      state.pictureMeta = meta
    },
    SET_PICTURE_LINKS(state, links) {
      state.pictureLinks = links
    },

    //Open Cases
    SET_OUTSTANDING_CASES(state, cases) {
      state.outstandingCases = cases
    }


  },
  actions: {
    getFormValues ({ commit }) {
      //commit('RESET_MSGS')
      commit('SET_LOADING', true)
      Vue.axios.get('api/cases/getFormValues')  
        .then((response) => {
          commit('SET_LOADING', false)
          commit('SET_FORM_VALUES', response.data)
        })   
        .catch((error) => {
          commit('SET_LOADING', false)
          commit('SET_ERRORS', getError(error))
          commit('SET_ERROR_MSG', error.response.data.message)
        })
    },
    /* paginateUsers({ commit }, link) {
      commit("SET_LOADING", true);
      CaseService.paginateCases(link)
        .then((response) => {
          setPaginatedCases(commit, response, );
        })
        .catch((error) => {
          commit('SET_LOADING', false);
          commit('SET_ERROR', getError(error));
          commit('SET_ERROR_MSG', error.response.data.message)
        });
    }, */
    addCase ({ commit }, payload) {
      commit('RESET_MSGS')
      commit('SET_LOADING', true)
      const formData = jsonToFormData(payload)
      return Vue.axios.post('api/cases/addCase', formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
      })
      .then((response) => {
        console.log(response)
        commit('SET_SUCCESS_MSG', response.data.message)
        commit('SET_LOADING', false)
        return true
      })
      .catch((error) => {
        commit('SET_LOADING', false)
        commit('SET_ERRORS', getError(error))
        commit('SET_ERROR_MSG', error.response.data.message)
        return false
      })
      function buildFormData(formData, data, parentKey) {
        if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
          Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
          });
        } else {
          const value = data == null ? '' : data;
      
          formData.append(parentKey, value);
        }
      }
      function jsonToFormData(data) {
        const formData = new FormData();
        buildFormData(formData, data);
        return formData;
      }
    },
    async updateCase({ commit }, payload) {
      commit('RESET_MSGS')
      commit('SET_LOADING', true)
      const formData = jsonToFormData(payload)
      return await Vue.axios.post('api/cases/updateCase', formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
      })
      .then((response) => {
        commit('SET_SUCCESS_MSG', response.data.message)
        commit('SET_LOADING', false)
        return true
      })
      .catch((error) => {
        commit('SET_LOADING', false)
        commit('SET_ERRORS', getError(error))
        commit('SET_ERROR_MSG', error.response.data.message)
        return false
      })
      function buildFormData(formData, data, parentKey) {
        if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
          Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
          });
        } else {
          const value = data == null ? '' : data;
      
          formData.append(parentKey, value);
        }
      }
      function jsonToFormData(data) {
        const formData = new FormData();
        buildFormData(formData, data);
        return formData;
      }
    },
    getOutstandingCases ({ commit }, page) {
      commit('RESET_MSGS')
      commit('SET_LOADING', true)
      CaseService.getOutstandingCases(page) 
        .then((response) => {
          commit('SET_OUTSTANDING_CASES', response.data)
          commit('SET_LOADING', false)
        })   
        .catch((error) => {
          commit('SET_LOADING', false)
          commit('SET_ERRORS', getError(error))
          commit('SET_ERROR_MSG', error.response.data.message)
        })
    },
    getParentCase ({ commit }, payload) {
      commit('RESET_MSGS')
      commit('SET_LOADING', true)
      return CaseService.getParentCase(payload) 
        .then((response) => {
          commit('SET_LOADING', false)
          return response.data.cases
        })   
        .catch((error) => {
          commit('SET_LOADING', false)
          commit('SET_ERRORS', getError(error))
          commit('SET_ERROR_MSG', error.response.data.message)
        })
    },
    async getEditCase ({ commit }, payload) {
      //commit('RESET_MSGS')
      commit('SET_LOADING', true)
      return await CaseService.getEditCase(payload) 
        .then((response) => {
          commit('SET_LOADING', false)
          return response.data.cases
        })   
        .catch((error) => {
          commit('SET_LOADING', false)
          commit('SET_ERRORS', getError(error))
          commit('SET_ERROR_MSG', error.response.data.message)
          return false
        })
    },
    deleteCase({ commit }, payload) {
      commit('SET_LOADING', true)
      return CaseService.deleteCase(payload)
        .then(() => {
          commit('SET_LOADING', false)
          return true
        })
        .catch((error) => {
          commit('SET_LOADING', false)
          commit('SET_ERRORS', getError(error))
          commit('SET_ERROR_MSG', error.response.data.message)
        })
    },
    searchCases ({ commit }, payload) {
      commit('RESET_MSGS')
      commit('SET_LOADING', true)
      CaseService.searchCases(payload) 
        .then((response) => {
          setPaginatedCases(commit, response, "SET_SEARCH_CASES");
          commit('SET_LOADING', false)
        })   
        .catch((error) => {
          commit('SET_LOADING', false)
          commit('SET_ERRORS', getError(error))
          commit('SET_ERROR_MSG', error.response.data.message)
        })
    },
    searchPictures ({ commit }, payload) {
      commit('RESET_MSGS')
      commit('SET_LOADING', true)
      CaseService.searchCases(payload) 
        .then((response) => {
          setPaginatedPictures(commit, response, "SET_SEARCH_PICTURES");
          commit('SET_LOADING', false)
        })   
        .catch((error) => {
          commit('SET_LOADING', false)
          commit('SET_ERRORS', getError(error))
          commit('SET_ERROR_MSG', error.response.data.message)
        })
    },
  }
}