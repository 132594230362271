import Vue from 'vue'

export default {
  //Used for Dashboard
  getOutstandingCases(payload) {
    return Vue.axios.post('api/cases/getOutstandingCases', payload);
  },
  getOpportunityCases(payload) {
    return Vue.axios.post('api/cases/getBeautificationOpportunitiesCases', payload);
  },
  searchCases(payload) {
    /* var url =  `api/cases/search?
                page=${payload.page}&
                per_page=${payload.per_page}&
                case_no=${payload.case_no}&
                status_id=${payload.status_id}
               ` */
    return Vue.axios.post('api/cases/search', payload); /* eslint-disable-line */
  },
  getEditCase(payload) {
    return Vue.axios.get('api/cases/getCase/' + payload); 
  },
  getParentCase(payload) {
    return Vue.axios.get('api/cases/getParentCase/' + payload); 
  },
  paginateCases(link) {
    return Vue.axios.get(link);
  },

  deleteCase(payload) {
    return Vue.axios.post('api/cases/delete/' + payload)
  }
};