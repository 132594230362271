<template>
  <v-form @submit.prevent="submit">
      <v-container class="mt-2">
          <v-row  justify="center" align="center">
            <StatusMessage :successmsg="success_msg" :errormsg="error_msg" style="width: 500px;"></StatusMessage>
          </v-row>
          <v-row justify="center" align="center">
            <v-card class="white--text" color="secondary" width="500" dark>
              <v-card-title class="pt-8 white--text justify-center">
                Reset Password
              </v-card-title>
              <v-card-text>
                  <v-col
                    cols="12"
                  >
                    <v-text-field
                      v-model="form.email"
                      :error-messages="errors.email"
                      label="E-mail"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                  >
                    <v-text-field
                      v-model="form.password"
                      type="password"
                      label="Password"
                      :error-messages="errors.password"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                  >
                    <v-text-field
                      v-model="form.password_confirmation"
                      type="password"
                      label="Confirm Password"
                      :error-messages="errors.password_confirmation"
                    ></v-text-field>
                  </v-col>
                </v-card-text>
                <v-card-actions class="justify-space-between my-4">
                  <v-btn text @click="toLogin">Back to Login</v-btn>
                  <v-btn rounded class="primary" type="submit">Reset Password</v-btn>
                </v-card-actions>
                <LoadingOverlay :loading="loading"></LoadingOverlay>
            </v-card>
          </v-row>
      </v-container>
  </v-form>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import StatusMessage from '@/components/utils/StatusMessage'
  import LoadingOverlay from '@/components/utils/LoadingOverlay'

  export default {
    name: 'ResetPassword',
    components: {
      StatusMessage,
      LoadingOverlay
    },
    data () {
      return {
        form: {
          token: this.$route.params.token,
          email: this.$route.query.email,
          password: '',
          password_confirmation: ''
        },
      }
    },
    computed: {
      ...mapGetters({
        authenticated: 'auth/authenticated',
        errors: 'auth/errors',
        error_msg: 'auth/error_msg',
        success_msg: 'auth/success_msg',
        loading: 'auth/loading'
      })
    },
    methods: {
      ...mapActions({
        resetPassword: 'auth/resetPassword',
      }),
      async submit () {
        await this.resetPassword(this.form)
      },
      toLogin () {
        this.$store.commit("auth/SET_ERRORS", [])
        this.$store.commit("auth/SET_ERROR_MSG", "")
        this.$store.commit("auth/SET_SUCCESS_MSG", "")
        this.$router.push({name: 'Login'})
      }
    }
  }
</script>