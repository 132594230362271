//Used By Form Management Page
import Vue from 'vue'
import { getError } from "@/utils/helpers"

export default {
  namespaced: true,
  state: {
    errors: [],
    error_msg: '',
    success_msg: '',
    loading: false
  },

  getters: {
    errors (state) {
      return state.errors
    },
    error_msg (state) {
      return state.error_msg
    },
    success_msg (state) {
      return state.success_msg
    },
    loading (state) {
      return state.loading
    }
  },

  mutations: {
    RESET_MSGS: function(state) { 
      state.errors = [] 
      state.error_msg = ""
      state.success_msg = ""
    },
    SET_ERRORS: function(state, value) { 
      state.errors = getError(value)
      state.error_msg = value.response.data.message
    },
    SET_ITEMS: function(state, value) { state.items = value },
    SET_ERROR_MSG: function(state, value) { state.error_msg = value },
    SET_SUCCESS_MSG: function(state, value) { state.success_msg = value },
    SET_LOADING: function(state, value) { state.loading = value }
  },
  actions: {
    async downloadGraffitiCase({commit}, payload) {
      commit('RESET_MSGS')
      commit('SET_LOADING', true)
      await Vue.axios.post('api/graffiti/exportGraffitiByIds', payload, {
          responseType: 'blob'
        })
        .then((response) => {
          const url = URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement("a");
          link.href = url;

          let test = response.headers['content-disposition'].split('filename=')[1].split('.')[0];
          let extension = response.headers['content-disposition'].split('.')[1].split(';')[0];
          const filename = `${test}.${extension}`;
          
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          link.remove();

          //commit('SET_MONTHLY_REPORT', response.data)
          commit('SET_LOADING', false)
        })
        .catch((error) => {
          commit('SET_ERRORS', error)
          commit('SET_LOADING', false)
      })
    },
    async downloadStatTracker({commit}, payload) {
      commit('RESET_MSGS')
      commit('SET_LOADING', true)
      await Vue.axios.post('api/graffiti/statTracker', payload, {
          responseType: 'blob'
        })
        .then((response) => {
          const url = URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement("a");
          link.href = url;

          let test = response.headers['content-disposition'].split('filename=')[1].split('.')[0];
          let extension = response.headers['content-disposition'].split('.')[1].split(';')[0];
          const filename = `${test}.${extension}`;
          
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          link.remove();

          //commit('SET_MONTHLY_REPORT', response.data)
          commit('SET_LOADING', false)
        })
        .catch((error) => {
          commit('SET_ERRORS', error)
          commit('SET_LOADING', false)
      })
    },

  }
}