"use strict";

import Vue from 'vue';
import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');

var options = {
  broadcaster: 'pusher',
  key: '20d5d2a01ad70caf3b57',
  cluster: 'us3',
  encrypted: true,
  authorizer: (channel) => {
      return {
          authorize: (socketId, callback) => {
              Vue.axios.post('/api/broadcasting/auth', {
                  socket_id: socketId,
                  channel_name: channel.name
              })
              .then(response => {
                  callback(false, response.data);
              })
              .catch(error => {
                  callback(true, error);
              });
          }
      };
  }
};
window.Echo = new Echo(options);
Vue.prototype.$echo = window.Echo
Vue.prototype.echo = window.Echo
 