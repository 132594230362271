//import router from "@/router"
import Vue from 'vue'
//import { getError } from "@/utils/helpers"

export default {
  namespaced: true,
  state: {
    message: '',
    messages: [],
    last_page: 1,
    errors: [],
    error_msg: '',
    success_msg: '',
    loading: false
  },

  getters: {
    message (state) {
      return state.message
    },
    messages (state) {
      return state.messages
    },
    errors (state) {
      return state.errors
    },
    error_msg (state) {
      return state.error_msg
    },
    success_msg (state) {
      return state.success_msg
    },
    loading (state) {
      return state.loading
    },
    last_page (state) {
      return state.last_page
    }
  },

  mutations: {
    RESET_MSGS: function(state) { 
      state.errors = [] 
      state.error_msg = ""
      state.success_msg = ""
    },
    SET_ERRORS: function(state, value) { state.errors = value },
    SET_ERROR_MSG: function(state, value) { state.error_msg = value },
    SET_SUCCESS_MSG: function(state, value) { state.success_msg = value },
    SET_LOADING: function(state, value) { state.loading = value },
    SET_LAST_PAGE: function(state, value) { state.last_page = value },
    SET_MSG: function(state, value) { state.msg = value },
    RESET_MESSAGES: function(state, value) { 
      state.messages = [...value];
    },
    SET_MESSAGES: function(state, value) { 
      state.messages.unshift(...value)
    }
  },
  actions: {
    //Temp
    async loadMessages ({ commit }, page) {
      commit('RESET_MSGS')
      await Vue.axios.get(`api/messages?page=${page}`)
        .then((response) => {
          if(page == 1)
            commit('RESET_MESSAGES', response.data.data.reverse())
          else
            commit('SET_MESSAGES', response.data.data.reverse())
          commit('SET_LAST_PAGE', response.data.last_page)
        })
        .catch((error) => {
          commit('SET_ERRORS', error)
      })
    },
    sendMessage ({ commit }, payload) {
      //let chatID = payload.chatID
      commit('SET_LOADING', true)
      const message = {
        id: payload.id,
        content: payload.content,
        date: payload.date
      }
      
      Vue.axios.put('api/messages', message)
        .then((response) => {
          commit('SET_MSG', response.data.message)
          commit('SET_LOADING', false)
        })
        .catch((error) => {
          commit('SET_ERRORS', error)
          commit('SET_LOADING', false)
      })
      /*firebase.database().ref('messages').child(chatID).child('messages').push(message)
        .then(
          (data) => {
          }
        )
        .catch(
          (error) => {
            console.log(error)
          }
        )*/
    },
  }
}