//Used By Form Management Page
import Vue from 'vue'
import { getError } from "@/utils/helpers"

export default {
  namespaced: true,
  state: {
    errors: [],
    error_msg: '',
    success_msg: '',
    loading: false,
    group_by_field: [],
    group_by_range: [],
    return_data: [],
    monthly_reports: [],
  },

  getters: {
    errors (state) {
      return state.errors
    },
    error_msg (state) {
      return state.error_msg
    },
    success_msg (state) {
      return state.success_msg
    },
    loading (state) {
      return state.loading
    },
    getGroupByField (state) {
      return state.group_by_field
    },
    getGroupByRange (state) {
      return state.group_by_range
    },
    getReturnData (state) {
      return state.return_data
    },
    getMonthlyData (state) {
      return state.monthly_reports
    },

  },

  mutations: {
    RESET_MSGS: function(state) { 
      state.errors = [] 
      state.error_msg = ""
      state.success_msg = ""
    },
    SET_ERRORS: function(state, value) { 
      state.errors = getError(value)
      state.error_msg = value.response.data.message
    },
    SET_ITEMS: function(state, value) { state.items = value },
    SET_ERROR_MSG: function(state, value) { state.error_msg = value },
    SET_SUCCESS_MSG: function(state, value) { state.success_msg = value },
    SET_LOADING: function(state, value) { state.loading = value },
    SET_GROUP_BY_FIELD: function(state, value) { state.group_by_field = value },
    SET_GROUP_BY_RANGE: function(state, value) { state.group_by_range = value },
    SET_RETURN_DATA: function(state, value) {state.return_data = value },
    SET_MONTHLY_REPORT: function(state, value) {state.monthly_reports = value }
  },
  actions: {
    async getGroupByFormFields ({ commit }) {
      commit('RESET_MSGS')
      commit('SET_LOADING', true)
      await Vue.axios.get('api/report/groupByFormFields')
        .then((response) => {
          commit('SET_GROUP_BY_FIELD', response.data.group_by_field)
          commit('SET_GROUP_BY_RANGE', response.data.group_by_range)
          commit('SET_LOADING', false)
        })
        .catch((error) => {
          commit('SET_ERRORS', error)
          commit('SET_LOADING', false)
      })
    },
    async runReport({commit}, payload) {
      commit('RESET_MSGS')
      commit('SET_RETURN_DATA', [])
      commit('SET_LOADING', true)
      await Vue.axios.post('api/report/runReport', payload)
        .then((response) => {
          commit('SET_RETURN_DATA', response.data)
          commit('SET_LOADING', false)
        })
        .catch((error) => {
          commit('SET_ERRORS', error)
          commit('SET_LOADING', false)
      })
    },
    async runRestitutionReport({commit}, payload) {
      commit('RESET_MSGS')
      commit('SET_LOADING', true)
      await Vue.axios.post('api/report/runRestitutionReport', payload)
        .then((response) => {
          commit('SET_RETURN_DATA', response.data)
          commit('SET_LOADING', false)
        })
        .catch((error) => {
          commit('SET_ERRORS', error)
          commit('SET_LOADING', false)
      })
    },
    async runMonthlyReport({commit}, payload) {
      commit('RESET_MSGS')
      commit('SET_RETURN_DATA', [])
      commit('SET_LOADING', true)
      await Vue.axios.post('api/report/runMonthlyReport', payload)
        .then((response) => {
          commit('SET_MONTHLY_REPORT', response.data)
          commit('SET_LOADING', false)
        })
        .catch((error) => {
          commit('SET_ERRORS', error)
          commit('SET_LOADING', false)
      })
    },
    async runWeeklyReport({commit}, payload) {
      commit('RESET_MSGS')
      commit('SET_RETURN_DATA', [])
      commit('SET_LOADING', true)
      await Vue.axios.post('api/report/runWeeklyReport', payload)
        .then((response) => {
          commit('SET_MONTHLY_REPORT', response.data)
          commit('SET_LOADING', false)
        })
        .catch((error) => {
          commit('SET_ERRORS', error)
          commit('SET_LOADING', false)
      })
    },
    async runYearToDateReport({commit}, payload) {
      commit('RESET_MSGS')
      commit('SET_RETURN_DATA', [])
      commit('SET_LOADING', true)
      await Vue.axios.post('api/report/runYearToDateReport', payload)
        .then((response) => {
          commit('SET_MONTHLY_REPORT', response.data)
          commit('SET_LOADING', false)
        })
        .catch((error) => {
          commit('SET_ERRORS', error)
          commit('SET_LOADING', false)
      })
    },
    async runStatTrackerReport({commit}, payload) {
      commit('RESET_MSGS')
      commit('SET_LOADING', true)
      await Vue.axios.post('api/report/runStatTrackerReport', payload)
        .then((response) => {
          commit('SET_MONTHLY_REPORT', response.data)
          commit('SET_LOADING', false)
        })
        .catch((error) => {
          commit('SET_ERRORS', error)
          commit('SET_LOADING', false)
      })
    },
    async runRemovalOnTimeReport({commit}, payload) {
      commit('RESET_MSGS')
      commit('SET_LOADING', true)
      await Vue.axios.post('api/report/runRemovalOnTimeReport', payload)
        .then((response) => {
          commit('SET_MONTHLY_REPORT', response.data)
          commit('SET_LOADING', false)
        })
        .catch((error) => {
          commit('SET_ERRORS', error)
          commit('SET_LOADING', false)
      })
    }
  }
}