//import router from "@/router"
//import Vue from 'vue'
import { getError } from "@/utils/helpers"
import CaseService from "@/services/CaseService";

export default {
  namespaced: true,
  state: {
    errors: [],
    error_msg: '',
    success_msg: '',
    loading: false,
    opportunity_cases: []
  },
  getters: {
    errors (state) {
      return state.errors
    },
    error_msg (state) {
      return state.error_msg
    },
    success_msg (state) {
      return state.success_msg
    },
    loading (state) {
      return state.loading
    },
    opportunityCases: (state) => {
      return state.opportunity_cases
    },
  },

  mutations: {
    RESET_MSGS: function(state) { 
      state.errors = [] 
      state.error_msg = ""
      state.success_msg = ""
    },
    SET_ERRORS: function(state, value) { state.errors = value },
    SET_ERROR_MSG: function(state, value) { state.error_msg = value },
    SET_SUCCESS_MSG: function(state, value) { state.success_msg = value },
    SET_LOADING: function(state, value) { state.loading = value },

    SET_OPPORTUNITY_CASES(state, cases) {
      state.opportunity_cases = cases
    },
  },
  actions: {
    getOpportunityCases ({ commit }, page) {
      commit('RESET_MSGS')
      commit('SET_LOADING', true)
      CaseService.getOpportunityCases(page) 
        .then((response) => {
          commit('SET_OPPORTUNITY_CASES', response.data)
          commit('SET_LOADING', false)
        })   
        .catch((error) => {
          commit('SET_LOADING', false)
          commit('SET_ERRORS', getError(error))
          commit('SET_ERROR_MSG', error.response.data.message)
        })
    },
  }
}