export default function guest({ next, store }) {
  //const storageItem = JSON.parse(window.localStorage.getItem("authenticated"))
  if (store.getters["auth/authenticated"] && !store.getters["auth/authUser"]) {
    store.dispatch("auth/getAuthUser").then(() => {
      if (store.getters["auth/authUser"]) {
        next({ name: "About" });
      } else {
        store.dispatch("auth/setAuthenticated", false);
        next();
      }
    })
  } else {
    next();
  }
}