<template>
  <v-form v-model="valid">
    <v-container>
      <v-row v-if="success_msg || error_msg" justify="center">
        <v-col sm="6">
          <StatusMessage :successmsg="success_msg" :errormsg="error_msg"></StatusMessage>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col sm="6">
          <v-card>
            <v-card-title>Change Password</v-card-title>
            <v-card-text>
              <v-row no-gutters>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="form.current_password"
                    label="Current Password"
                    :error-messages="errors.current_password"
                    type="password"
                    required
                    autocomplete="new-password"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="form.password"
                    label="New Password"
                    :error-messages="errors.password"
                    type="password"
                    required
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="form.password_confirmation"
                    label="Confirm Password"
                    :error-messages="errors.password_confirmation"
                    type="password"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                class="mr-4"
                rounded
                @click="submit"
              >
                Change Password
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
            <LoadingOverlay :loading="loading"></LoadingOverlay>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import StatusMessage from "@/components/utils/StatusMessage"
  import LoadingOverlay from "@/components/utils/LoadingOverlay"

  export default {
    name: "EditPassword",
    components: {
      StatusMessage,
      LoadingOverlay
    },
    data: () => ({
      valid: false,
      form: {
        current_password: '',
        password: '',
        password_confirmation: ''
      }
    }),
    computed: {
      ...mapGetters({
        errors: 'auth/errors',
        error_msg: 'auth/error_msg',
        success_msg: 'auth/success_msg',
        loading: 'auth/loading'
      })
    },
    methods: {
      ...mapActions({
        changePassword: 'auth/changePassword',
      }),
      async submit() {
        var res = await this.changePassword(this.form)
        if(res) {
          this.form = {
            current_password: '',
            password: '',
            password_confirmation: ''
          }
        }
      }
    }
  }
</script>