<template>
  <v-app>
    <v-app-bar
      app
      class="primary d-print-none"
      dark
      short
    >
      <div class="d-flex align-center title">
        <v-img
          alt="Tag Tracker"
          class="shrink mt-1"
          contain
          min-width="105"
          src="/img/assets/text_logo15.png"
          width="105"
        />
      </div>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon class="d-flex d-sm-flex d-md-none" @click="drawer = true"></v-app-bar-nav-icon>
      <template v-for="link in links">
        <v-btn class="hidden-sm-and-down" v-if="hasAccess(link)" :key="link.url" link text :to="link.to" @click="handle(link.f)">
          {{ link.name }}
        </v-btn>
      </template>
      <v-menu 
        offset-y
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            inset
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-cog</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-switch
              v-model="getDarkMode"
              label="Dark Mode"
              persistent-hint
            ></v-switch>
          </v-list-item>
          <v-list-item>
            Version {{ version }}
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu
        v-if="authenticated === true"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            v-bind="attrs"
            v-on="on"
          >
            Account <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list
          dense
        >
          <v-list-item
            class="text-end"
            @click.prevent="editPassword"
          >
            <v-list-item-title>Update Password</v-list-item-title>
          </v-list-item>
          <v-list-item
            class="text-end"
            @click.prevent="logout"
          >
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        @click="toLogin"
        text
        v-else
      >
        <span class="mr-2">Login</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          active-class="deep-purple--text text--accent-4"
        >
          <template v-for="link in links">
            <v-list-item v-if="hasAccess(link)" :key="link.url" @click="handle(link.f)">
              <v-list-item-title>{{link.name}}</v-list-item-title>
            </v-list-item>
          </template>
          <v-list-item v-if="authenticated === true" @click="handle('editPassword')">
            <v-list-item-title>Update Password</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="authenticated === true" @click="handle('logout')">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
          <v-list-item v-else @click="handle('toLogin')">
            <v-list-item-title>Login</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main id="scroll-target" style="max-height: 93vh; overflow-y: scroll; margin-top: 56px; padding-top: 0px;">
      <router-view/>
    </v-main>
    <!-- <v-footer class="py-1 justify-center" padless>Graffiti Version: {{ version }}</v-footer> -->
    <v-snackbar bottom left :value="updateExists" :timeout="-1" color="primary">
      An update is available
      <v-btn text @click="refreshApp">
        Update
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
  import { mapGetters, mapActions, mapMutations } from 'vuex'
  var pjson = require('../package.json')

  export default {
    name: 'App',
    data: () => ({
      fab: false,
      closeConditional: false,
      registration: null,
      updateExists: false,
      refreshing: false,
      drawer: false,
      version: pjson.version,
      links: [
        { name: 'Open Cases', f: 'toPendingCases', isAdmin: false, allowReadOnly: false },
        { name: 'Find Cases', f: 'toSearchCases', isAdmin: false, allowReadOnly: true },
        { name: 'Add Cases', f: 'toAddCase', isAdmin: false, allowReadOnly: false },
        { name: 'Map', f: 'toMap', isAdmin: false, allowReadOnly: true },
        { name: 'Tools', f: 'toTools', isAdmin: false, allowReadOnly: true },
        { name: 'Management', f: 'toManagement', isAdmin: true, allowReadOnly: false },
      ]
    }),
    created() {
      document.addEventListener('swUpdated', this.updateAvailable, { once: true })
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        // We'll also need to add 'refreshing' to our data originally set to false.
        if (this.refreshing) return
        this.refreshing = true
        // Here the actual reload of the page occurs
        window.location.reload()
      })
    },
    computed: {
      ...mapGetters({
        authenticated: 'auth/authenticated',
        isAdmin: 'auth/isAdmin',
        isReadOnly: 'auth/isReadOnly'
      }),
      getDarkMode: {
        get() {
          return localStorage.getItem('dark-mode') == "true"
        },
        set(newValue) {
          this.$vuetify.theme.dark = newValue
          localStorage.setItem('dark-mode', newValue)
        }
      },
      //Might resort to CSS instead
      mobileWidths() {
        if(this.$vuetify.breakpoint.width > 1200)
          return this.$vuetify.breakpoint.width * .40
        else if(this.$vuetify.breakpoint.width > 992)
          return this.$vuetify.breakpoint.width * .60
        else if(this.$vuetify.breakpoint.width > 768)
          return this.$vuetify.breakpoint.width * .70
        else if(this.$vuetify.breakpoint.width > 600)
          return this.$vuetify.breakpoint.width * .80
        else 
          return this.$vuetify.breakpoint.width * .90
      }
    },
    mounted() {
  
    },
    methods: {
      ...mapActions({
        getOutstandingCase: 'cases/getOutstandingCases',
        logoutAction: 'auth/logout'
      }),
      ...mapMutations({
        clearAuthMessages: 'auth/RESET_MSGS'
      }),
      handle(f){
        this[f]()
      },
      hasAccess(link) {
        if (!this.authenticated === true) return false;
        if (this.isAdmin) return true; // Supercede all if admin
        if (link.isAdmin && this.isAdmin) return true;
        if (link.allowReadOnly && this.isReadOnly) return true;
        if (!link.isAdmin && link.allowReadOnly) return true;
      },
      updateAvailable(event) {
        console.log(event)
        this.registration = event.detail
        this.updateExists = true
      },
      refreshApp() {
        this.updateExists = false
        // Make sure we only send a 'skip waiting' message if the SW is waiting
        if (!this.registration || !this.registration.waiting) return
        // Send message to SW to skip the waiting and activate the new SW
        this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      },
      editPassword() {
        if (this.$router.currentRoute.name !== "EditPassword")  {
          this.clearAuthMessages()
          this.$router.push({name: 'EditPassword'})
        }
      },
      toManagement() {
        if (this.$router.currentRoute.name !== "Management")  {
          this.$router.push({name: 'Management'})
        }
      },
      toAddCase() {
        if (this.$router.currentRoute.name !== "AddCase")  {
          this.$router.push({name: 'AddCase'})
        }
      },
      toTools() {
        if (this.$router.currentRoute.name !== "Tools")  {
          this.$router.push({name: 'Tools'})
        }
      },
      toMap() {
        if (this.$router.currentRoute.name !== "IncidentMap")  {
          this.$router.push({name: 'IncidentMap'})
        }
      },
      toSearchCases() {
        if (this.$router.currentRoute.name !== "SearchCases")  {
          this.$router.push({name: 'SearchCases'})
        }
      },
      toPendingCases() {
        if (this.$router.currentRoute.name !== 'PendingCases') {
          this.$router.push({name: 'PendingCases'})
        }
        else {
          this.getOutstandingCase()
        }
      },
      toLogin() {
        if (this.$router.currentRoute.name !== "Login") {
          this.clearAuthMessages()
          this.$router.push({name: 'Login'})
        }
      },
      async logout() {
        await this.logoutAction()
      },
      setDarkMode() {
        if(localStorage.getItem('dark-mode') == "true") 
          localStorage.setItem('dark-mode', "false")
        else
          localStorage.setItem('dark-mode', "true")
      }
    }
  };
</script>
<style>
  .chat_dialog:before {
    content: "";
    pointer-events: none;
    position: absolute;
    top: 100%;
    right: 12px;
    width: 0;
    height: 0;
    border-left: 18px solid transparent;
    border-right: 18px solid transparent;
    border-top: 18px solid #009688;
    border-bottom: 18px solid transparent;
  }
  html {
    overflow-y: hidden !important;
  }

</style>