//Used By Form Management Page
import Vue from 'vue'
import { getError } from "@/utils/helpers"

export default {
  namespaced: true,
  state: {
    errors: [],
    error_msg: '',
    success_msg: '',
    loading: false,
    agencies: [],
  },

  getters: {
    errors (state) {
      return state.errors
    },
    error_msg (state) {
      return state.error_msg
    },
    success_msg (state) {
      return state.success_msg
    },
    loading (state) {
      return state.loading
    },
    getItems (state) {
      return state.agencies
    }
  },

  mutations: {
    RESET_MSGS: function(state) { 
      state.errors = [] 
      state.error_msg = ""
      state.success_msg = ""
    },
    SET_ERRORS: function(state, value) { 
      state.errors = getError(value)
      state.error_msg = value.response.data.message
    },
    SET_ERROR_MSG: function(state, value) { state.error_msg = value },
    SET_SUCCESS_MSG: function(state, value) { state.success_msg = value },
    SET_LOADING: function(state, value) { state.loading = value },
    SET_SUPPLIES: function(state, value) { state.agencies = value }
  },
  actions: {
    async loadItems ({ commit }) {
      commit('RESET_MSGS')
      commit('SET_LOADING', true)
      await Vue.axios.get('api/getAgencies')
        .then((response) => {
          commit('SET_SUPPLIES', response.data.agencies)
          commit('SET_LOADING', false)
        })
        .catch((error) => {
          commit('SET_ERRORS', error)
          commit('SET_LOADING', false)
      })
    },
    async addItem({ commit }, payload) {
      commit('RESET_MSGS')
      return await Vue.axios.put('api/addAgency', payload)
        .then((response) => {
          commit('SET_SUCCESS_MSG', response.data.message)
          return response.data.id
        })
        .catch((error) => {
          commit('SET_ERRORS', error)
          return false
      })
    },
    async updateItem({ commit }, payload) {
      commit('RESET_MSGS')
      return await Vue.axios.put('/api/updateAgency', payload)
        .then((response) => {
          commit('SET_SUCCESS_MSG', response.data.message)
          return true
        })
        .catch((error) => {
          commit('SET_ERRORS', error)
          return false
      })
    },
    async deleteItem({ commit }, payload) {
      commit('RESET_MSGS')
      return await Vue.axios.delete('api/deleteAgency/'+ payload)
        .then((response) => {
          commit('SET_SUCCESS_MSG', response.data.message)
          return true
        })
        .catch((error) => {
          commit('SET_ERRORS', error)
          return false
      })
    },
  }
}